const base = '/auth'

export const auth = {
    login: `${base}/login`,
    confirm_password: `${base}/compare-password`,
    create_account: `${base}/signup`,
    create_tag: `${base}/create-tag`,
    create_pin: `${base}/create-pin`,
    forgot_password: `${base}/forgot-password`,
    reset_password: `${base}/reset-password`,
    validate_password_reset_code: `${base}/validate-password-reset-code`,
    validate_otp: `${base}/validate-otp`,
    verify_account: `${base}/verify-email`,
    resend_verification_email: `${base}/resend-confirmation`,
    refreshToken: `${base}/refresh-token`,
    change_password: `${base}/change-password`,
    request_password_change: `${base}/request-pin-change`,
    security_questions: `${base}/security-questions`,
    create_security_question: `${base}/set-questions`,
    user_questions: `${base}/selected-questions`,
    validate_2fa: `${base}/validate-2fa`,
    validatePin: `${base}/validate-pin`,
    resendOtp: `${base}/resend-otp`
}